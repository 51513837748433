import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material";
import { globalSettings } from "./environments/environments";
import { Outlet } from "react-router-dom";
import { Window_Resize_Detector } from "./components/shared/window_resize";
import AOS from "aos";
import { useSelector } from "react-redux";
import { AppState } from "./store/models/corestore.interface";
import { CookieConsentSection } from "./components/shared/cookieConsentSection";

function App() {
  const theme_options = useSelector(
    (e: AppState) => e.app_overall_info.theme_options_client_FE,
  );

  const theme = createTheme(theme_options);

  AOS.init({
    duration: 2000,
  });
  return (
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <Helmet>
          <title>The Viet Media - Digital Marketing Agency</title>
          <link rel="icon" href="/favicon2345.ico" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
        </Helmet>
        <div className="App" style={{ fontFamily: globalSettings.fontPrimary }}>
          <Window_Resize_Detector />
          <CookieConsentSection />
          <Outlet />
        </div>
      </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;
